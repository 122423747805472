import React from "react";
import "../Styles/topbar.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
const Topbar = ({links,page_top_name}) => {
  const path = useLocation();

  // const links = [
  //   {
  //     link_path: "/",
  //     link_name: "Home",
  //   },
  //   {
  //     link_path: "/about",
  //     link_name: "About",
  //   },
  //   {
  //     link_path: "/social_responsibility",
  //     link_name: "Social Responsibility",
  //   },
  // ];
  return (
    <div className="topbar-parent parent bg-img-cover">
      <div className="topbar-cont cont">
      <h2> {page_top_name} </h2>
        <div className="breadcrumb">
          <p>
            {" "}
            {links.map((item, index) => (
              <Link className={path.pathname === item.link_path ? "active" : ""} to={item.link_path} >{item.link_name}    </Link> 
            ))}{" "}
            
          </p>
        </div>
      
      </div>
    </div>
  );
};

export default Topbar;
