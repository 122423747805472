import React, { useEffect } from "react";
import "../Styles/founders.scss";
import baburaoImg from "../Assets/raw/founder2.webp";
import Rekhatai from "../Assets/raw/founder3.webp";
import Omraje from "../Assets/raw/founder1.webp";
import Gauri from "../Assets/raw/founder4.webp";
// aos
import AOS from "aos";
import "aos/dist/aos.css";

function Founders() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* <div className="founders-sec bg-img-cover parent">
        <div className="overlay">
          <div className="founders-cont cont">
            <h1> founders </h1>
          </div>
        </div>
      </div> */}
      <div className="ourpillers-parent parent">
        <div className="overlay-img-second">
          <div className="our-pillers-cont cont">
            <div
              className="tag-founder"
              //  data-aos="fade-up" data-aos-delay="0" data-aos-duration="900"
            >
              <h2 className="heading" data-aos="fade-up" data-aos-delay="200">
                Our Pillars
              </h2>
            </div>
            <div className="founder-list">
              {/* founder 1 */}
              <div className="founders" data-aos="fade-up" data-aos-delay="300">
                <div className="founders-image">
                  <img src={baburaoImg} alt="" />
                </div>
                <div className="founders-content">
                  <h3>Mr. Baburao Botrepatil</h3>
                  <p>Chairman & MD</p>
                </div>
              </div>
              {/* founder 2 */}
              <div className="founders" data-aos="fade-up" data-aos-delay="400">
                <div className="founders-image">
                  <img src={Rekhatai} alt="" />
                </div>
                <div className="founders-content">
                  <h3>Mrs. Rekhatai Botrepatil</h3>
                  <p>Director</p>
                </div>
              </div>
              {/* founder 3 */}
              <div className="founders" data-aos="fade-up" data-aos-delay="500">
                <div className="founders-image">
                  <img src={Omraje} alt="" />
                </div>
                <div className="founders-content">
                  <h3>Mr. Omraje Botrepatil</h3>
                  <p>Director</p>
                </div>
              </div>
              {/* founder 4 */}
              <div className="founders" data-aos="fade-up" data-aos-delay="600">
                <div className="founders-image">
                  <img src={Gauri} alt="" />
                </div>
                <div className="founders-content">
                  <h3> Ms. Gauri Botrepatil</h3>
                  <p>Director</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Founders;
