import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../Styles/herosection.scss";
import ReactPlayer from 'react-player';
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import video from '../Assets/herovideos/3DesktopNew.webm'
import vmobile from "../Assets/herovideos/NMob.webm";

// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function HeroSection() {



  const [swiperRef, setSwiperRef] = useState(null);




  return (
    <>

      <div className="herosection-parent">
        <div className="video-section desktop">
          <ReactPlayer
            url={video}
            playing={true}
            playsinline={true}
            muted={true}
            loop={true}
            controls={false}
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
        <div className="video-section mobile">
          <ReactPlayer
            url={vmobile}
            playing={true}
            playsinline={true}
            muted={true}
            loop={true}
            controls={false}
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
        <div className="overlay">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={false}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="heroSwiper"
          >
            <SwiperSlide> <h1 className="hero-heading" data-aos="zoom-in-up"><span className="highlight">Cultivating Communities</span> with Innovation and Sustainability</h1></SwiperSlide>
            <SwiperSlide> <h1 data-aos="zoom-in-up" className="hero-heading"><span className="highlight">
            Sweeter Horizons,</span> Bolder Dreams</h1></SwiperSlide>
            <SwiperSlide> <h1 data-aos="zoom-in-up" className="hero-heading"><span className="highlight">Sweetness</span> Beyond Boundaries</h1></SwiperSlide>

          </Swiper>

        </div>
      </div>

      {/* <div className="hero-section">
        <Swiper
          onSwiper={setSwiperRef}
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            type: 'fraction',
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide className="bg-img-cover slide-image">
            <div className="overlay">
              <h1 data-aos="fade-up" data-aos-delay="200" >
                Sweeter Horizons, Bolder Dreams
              </h1>
              <Link className="btn" to="/products" data-aos="fade-up" data-aos-delay="300"> Get Started </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-img-cover slide-image">
            <div className="overlay">
              <h1 data-aos="fade-up"  >
                Cultivating Communities with Innovation and Sustainability

              </h1>
              <Link className="btn" to="/contact" data-aos="fade-up"> Contact Us</Link>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-img-cover slide-image">
            <div className="overlay">
              <h1 data-aos="fade-up" data-aos-delay="5400">
                Sweetness Beyond Boundaries
              </h1>
              <Link className="btn" to="/about" data-aos="fade-up" data-aos-delay="5500"> Know More</Link>
            </div>
          </SwiperSlide>
        </Swiper>
        
      </div> */}
    </>
  );
}
