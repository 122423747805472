import React from "react";
import "../Styles/environment.scss";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import Topbar from "../Comps/Topbar";
import { Helmet } from "react-helmet";

function Environment() {
  const links = [
    {
      link_path: "/",
      link_name: "Home",
    },
    {
      link_path: "/",
      link_name: "Our Empact",
    },
    {
      link_path: "/about",
      link_name: "Environment",
    },
  ];
  return (
    <>
    <Helmet>
  <title>Environment Initiatives - Green Belt Development and Sustainability</title>
  <meta name="description" content="Learn about our commitment to environmental sustainability, including green belt development, local employment, medical camps, road development, and water conservation efforts." />
  <meta name="keywords" content="environment initiatives, green belt development, local employment, medical camps, CER funds, road development, condensate recycling, water conservation, sustainability, Onkar Sakhar Karkhana" />
  <link rel="canonical" href="https://onkarsugars.com/environment" />
</Helmet>

      <div className="environment-sec bg-img-cover parent">
        <div className="overlay">
          <div className="environment-cont cont">
            <Topbar links={links} page_top_name="Environment" />
          </div>
        </div>
      </div>

 
            {/* <div className="environment-sec bg-img-cover parent">
                <div className="overlay">
                    <div className="environment-cont cont">
                   
                    </div>
                </div>
            </div> */}
             <Topbar links={links} page_top_name = "Environment" />
 
            <div className="environment-parent parent">
                <div className="environment-container cont" data-aos="fade-up"  data-aos-delay="100">
                    <div className="environment-left">
                        <h2 className="env-title">Environment Document</h2>

                        <div className="list-container" data-aos="fade-up"  >
                            {[
                                "10 lakhs capital and 3 lakhs recurring for green belt development and maintenance.",
                                "Local people will be prioritised for employment in the industry.",
                                "Medical camps arranged in the study area under CER funds.",
                                "Road development for site approach and village roads under CER activities.",
                                "Excess condensate treated and recycled for process water, gardening and dust suppression."
                            ].map((text, index) => (
                                <div className='list-div' key={index} data-aos-delay={index * 200}>
                                    <span className='env-icon'><MdOutlineKeyboardDoubleArrowRight /></span>
                                    <p>{text}</p>
                                </div>
                            ))}
                        </div>
                        <div className="env-btn-div">
                            <a
                                className="btn"
                                href={require('../Assets/pdf/ECL_AUTH_327356_IA_MH_IND2_421345_2023.pdf')}
                                download="Environment_Document.pdf"
                                
                                
                            >
                                Download Pdf
                            </a>
                        </div>
                    </div>

                    <div className="environment-right">
                        <div className="env-right-img bg-img-cover"></div>
                    </div>
                </div>
            </div>
       

          <div className="environment-right">
            <div className="env-right-img bg-img-cover"></div>
          </div>
      
    
    </>
  );
}

export default Environment;
