import React from 'react';
import "../Styles/factory2.scss";
import fac1 from "../../src/Assets/factory/fac1.webp";
import fac2 from "../../src/Assets/factory/fac2.webp";
import fac3 from "../../src/Assets/factory/fac3.webp";
import fac4 from "../../src/Assets/factory/fac4.webp";
import fac5 from "../../src/Assets/factory/fac5.webp";
import fac6 from "../../src/Assets/factory/fac6.webp";
import fac7 from "../../src/Assets/factory/fac7.webp";
import fac8 from "../../src/Assets/factory/fac8.webp";
import fac9 from "../../src/Assets/factory/fac9.webp";

function Factory2() {
  const factories = [
    {
      num: "Factory 1",
      name: 'Onkar Sakhar Karkhana Pvt. Ltd.',   
      address: 'Chandapuri, Taluka Malshiras, District Solapur-413310',
      location: '',
      description: 'This unit boasts a sugar daily crushing capacity of 6500 TCD, accompanied by a distillery producing 150 KLPD and a  cogeneration facility generating 20 MW of power. Situated in  Chandapuri, Tal. Malshiras, Dist. Solapur, the unit also hosts a bio CNG project with a capacity of 12 TPH, a compost fertiliser project producing 200 MT, and an Indian Oil petrol pump.',
      image: fac1,
    },
    {
        num: 'Factory 2',
        name:'Onkar Sakhar Karkhana Pvt. Ltd.',
      address: 'Ambulga, Taluka Nilanga, District Latur-413530',
      location: '',
      description: 'This unit features a sugar crushing capacity of 4500 TCD, supported by a distillery producing 150 KLPD. Additionally, it includes a diesel pump, a CNG generation project, and an organic fertiliser project, ensuring a comprehensive and  sustainable operational setup.',
      image: fac2,
    },
    {
        num: 'Factory 3',
        name:'Onkar Sugar and Distillery Power Pvt. Ltd.',
      address: 'Farale, Taluka Radhanagari, District Kolhapur-416231',
      location: '',
      description: 'This unit is equipped with a sugar crushing capacity of 2500 TCD, complemented by a cogeneration facility generating 20 MW   of electricity, ensuring efficient energy utilisation and  sustainability. The integrated setup supports streamlined operations and long-term productivity.',
      image: fac3,
    },
    {
        num: 'Factory 4',
        name:'Gauri Sugar and Distilleries Pvt. Ltd.',
      address: 'Hiradgaon, Taluka Shrigonda, District Ahmednagar-413701',
      location: '',
      description: 'This unit boasts a sugar crushing capacity of 10,000 TCD, accompanied by a distillery producing 80 KLPD and a cogeneration facility generating 40 MW of electricity. Additionally, the setup includes a diesel pump, ensuring seamless operations and energy efficiency.',
      image: fac4,
    },
    {
        num: 'Factory 5',
        name:'Onkar Power Corporations Pvt. Ltd.',
      address: 'Tadwal, Taluka Akkalkot, District Solapur-413219',
      location: '',
      description: 'This unit features a sugar crushing capacity of 6500 TCD, supported by a cogeneration facility generating 20 MW of electricity. The integrated design ensures efficient energy utilisation and sustainable operations.',
      image: fac5,
    },
    {
        num: 'Factory 6',
        name:'Onkar Sugar Corporations Pvt. Ltd.',
      address: ' Mhaisgaon, Taluka Madha, District Solapur-413208',
      location: '',
      description: 'This unit is equipped with a sugar crushing capacity of 6000 TCD, complemented by a distillery producing 250 KLPD and a cogeneration facility generating 20 MW of electricity. The setup ensures high efficiency and sustainable operations.',
      image: fac6,
    },
    {
        num: 'Factory 7',
        name:'Onkar Sugar and Allied Industries Pvt.Ltd.',
      address: 'Deodaithan, Taluka Shrigonda, District Ahmednagar-413702',
      location: '',
      description: 'This unit includes a sugar crushing capacity of 2000 TCD, supported by a cogeneration facility generating 5 MW of  electricity. Additionally, it features a diesel pump to  facilitate seamless and efficient operations.',
      image: fac7,
    },
    {
        num: 'Factory 8',
        name:'Onkar Sakhar Karkhana Pvt. Ltd.',
      address: 'Pangari, Taluka Parli, District Beed-431530',
      location: '',
      description: 'This unit boasts a sugar crushing capacity of 6500 TCD, accompanied by a distillery producing 75 KLPD and a cogeneration facility generating 20 MW of electricity. The setup ensures efficient and sustainable operations.',
      image: fac8,
    },
    {
        num: 'Factory 9',
        name:'Onkar Sugar and Allied Industries Pvt. Ltd.',
      address: 'Purushottam Nagar, Taluka Shahada, District Nandurbar-425409',
      location: '',
      description: 'This unit features a sugar crushing capacity of 5000 TCD, supported by a distillery producing 80 KLPD. It also includes a petrol pump, a bottling project, and an organic fertiliser  project, ensuring a well-rounded and sustainable operational framework.',
      image: fac9,
    },
  ];

  return (
    <div className="factory-container">
    {factories.map((factory, index) => (
      <div key={index} className={`factory-section ${index % 2 === 0 ? 'odd' : 'even'}`}  >
        <h3 className="numf" data-aos="fade-up" data-aos-delay={index * 100}>{factory.num}</h3> {/* Move it outside */}
        <div className="factory-box" data-aos="fade-up" data-aos-delay={index * 100}>
          <div className="factory-image">
            <img src={factory.image} alt={factory.name} />
          </div>
          <div className="factory-content">
            <h2>{factory.name}</h2>
            <p className="addressf">{factory.address}</p>
            <p>{factory.location}</p>
            <p>{factory.description}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
  
  );
}

export default Factory2;


// error type

