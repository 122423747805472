import React, { useEffect, useState } from "react";
import "../Styles/counter.scss";
import CountUp from "react-countup";
import { GiSugarCane } from "react-icons/gi";
import { FaSackDollar } from "react-icons/fa6";
import { BiSolidFactory } from "react-icons/bi";
import { IoPeopleSharp } from "react-icons/io5";
import { GiFarmer } from "react-icons/gi";

function Counter() {
  const [trigger, setTrigger] = useState(false); 

  useEffect(() => {
    const interval = setInterval(() => {
      setTrigger((prev) => !prev);
    }, 2000 * 5);

    return () => clearInterval(interval); 
  }, []);
  return (
    <>
      <div className="counter-parent parent">
        <div className="overlay-img ">
          <div
            className="counter-cont cont"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="counter-tag">
              <h2 className="heading">Our Produce In Numbers</h2>
            </div>
            <div className="counter-number">
              <div
                className="counter-list"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="counter-list-top">
                  <div className="counter-round">
                    <div className="counting-number">
                      <h3>
                        <CountUp start={0} end={7.2} key={trigger} duration={5} decimals={1} suffix="M" />
                      </h3>
                    </div>
                    <h5>MT Annually</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Annual Sugar Production</p>
                </div>
              </div>
              <div
                className="counter-list"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="counter-list-top">
                  <div className="counter-round">
                    <div className="counting-number">
                      <h3>
                        <CountUp start={0}end={1100} key={trigger} duration={5} />
                      </h3>
                    </div>
                    <h5>KLPD</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Distillery Project Capacity</p>
                </div>
              </div>
              <div
                className="counter-list"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="counter-list-top">
                  <div className="counter-round">
                    <div className="counting-number">
                      <h3>
                        <CountUp start={0} end={120} key={trigger} duration={5}  />
                      </h3>
                    </div>
                    <h5>MW per day</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Cogeneration Project Capacity</p>
                </div>
              </div>
              <div
                className="counter-list"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="counter-list-top">
                  <div className="counter-round">
                    <div className="counting-number">
                      <h3>
                        <CountUp   start={0} end={36} key={trigger} duration={5}   />
                      </h3>
                    </div>
                    <h5>TPH</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Bio CNG Capacity</p>
                </div>
              </div>
              <div
                className="counter-list"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div className="counter-list-top">
                  <div className="counter-round">
                    <div className="counting-number">
                      <h3>
                        <CountUp  start={0} end={30000} key={trigger} duration={5}  />
                      </h3>
                    </div>
                    <h5>MT Annually</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Compost Fertiliser Production</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Counter;
