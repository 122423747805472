import React, { useEffect } from "react";
import "../Styles/products.scss";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Counter from "../Comps/Counter";
import Topbar from "../Comps/Topbar";
import { Helmet } from "react-helmet";

function Products() {
  useEffect(() => {
    AOS.init();
  }, []);

  const links = [
    {
      link_path: "/",
      link_name: "Home",
    },
    {
      link_path: "/products",
      link_name: "Product",
    },
  ];

  return (
    <>
  <Helmet>
  <title>Onkar Sakhar Karkhana Pvt. Ltd. - Products | Sugar, Distillery, Cogeneration & Fertiliser</title>
  <meta name="description" content="Onkar Sakhar Karkhana Pvt. Ltd. offers a wide range of products including high-quality sugar, distillery operations, cogeneration facilities, and organic fertilisers. Discover more about our innovative, sustainable, and eco-friendly solutions." />
  <meta name="keywords" content="Onkar Sakhar Karkhana, sugar production, distillery, cogeneration, renewable energy, organic fertilisers, compost, eco-friendly, sustainable agriculture, Maharashtra, Onkar Group products, industrial products, green energy, energy efficiency" />

  <link rel="canonical" href="https://onkarsugars.com/products" />

  </Helmet>
      <Topbar links={links} page_top_name="Product" />
       

      {/* products */}
      <div className="productss-parent parent">
        <div
          className="productss-cont cont"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={false}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide className="swiperslides-products">
              <div
                className="swiperslides-left"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="900"
              >
                <div className="products-img sugar-img bg-img-cover"></div>
              </div>
              <div
                className="swiperslides-right"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="900"
              >
                <h2>sugar</h2>
                <p>
                  Onkar Sakhar Karkhana Pvt. Ltd. excels in sugar production,
                  leveraging advanced facilities and a favourable agricultural
                  landscape. With an impressive annual capacity of 7.2 million
                  metric tons, the company consistently delivers premium-quality
                  sugar for diverse industrial and consumer applications.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiperslides-products">
              <div className="swiperslides-left">
                <div className="products-img distlillery-img bg-img-cover"></div>
              </div>
              <div className="swiperslides-right">
                <h2>Distillery</h2>
                <p>
                  Onkar Sakhar Karkhana Pvt. Ltd. operates distilleries with
                  capacities from 150 KLPD to 300 KLPD, producing a combined
                  1,100 KLPD daily. These facilities cater to various industrial
                  uses, showcasing the company’s focus on innovation and
                  value-added products.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiperslides-products">
              <div className="swiperslides-left">
                <div className="products-img Cogeneration-img bg-img-cover"></div>
              </div>
              <div className="swiperslides-right">
                <h2>Cogeneration</h2>
                <p>
                  Onkar Sakhar Karkhana Pvt. Ltd. generates renewable energy
                  through cogeneration facilities, with capacities between 8.5
                  MW and 40 MW, producing 120 MW daily. This commitment to
                  sustainability supports regional power needs while reducing
                  environmental impact.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiperslides-products">
              <div className="swiperslides-left">
                <div className="products-img Fertiliser-img bg-img-cover"></div>
              </div>
              <div className="swiperslides-right">
                <h2>Fertiliser</h2>
                <p>
                  With a daily output of 200 metric tons and an annual capacity
                  of 30,000 metric tons, Onkar Sakhar Karkhana Pvt. Ltd.
                  produces organic compost fertilisers. This initiative promotes
                  sustainable agriculture by enhancing soil health and
                  supporting eco-friendly farming practices.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      {/* counter */}
      <Counter />
    </>
  );
}

export default Products;
