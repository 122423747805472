import React, { useEffect, useState } from "react";
import "../Styles/home.scss";
import HeroSection from "../Comps/HeroSection";

import TwoColSec from "../Comps/TwoColSec";
import factorysecondimg from "../Assets/raw/bg2.webp";
import { Link } from "react-router-dom";
import Image from "../Assets/raw/bg3.png";
import dummyImg from "../Assets/raw/dummy-img.jpg";
import product from "../Assets/raw/products.webp";
import about3 from "../Assets/factory/fac4.webp";
import about4 from "../Assets/factory/fac7.webp";
import bgtop2 from "../Assets/self-info-bg.png";
// aos
import AOS from "aos";
import "aos/dist/aos.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import img from "../Assets/gallery/img1.jpg";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Gallerypage from "./Gallerypage";
import Gallery from "../Comps/Gallery";
import BlogCard from "../Comps/BlogCard";
import { fetchBlogs, fetchImages } from "../services/service";
import { Helmet } from "react-helmet";
const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 10, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.02, // 2 = 200%, 1.5 = 150%, etc..
  speed: 10, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [BlogData, setBlogData] = useState([]);
  const getBlogs = async () => {
    try {
      const response = await fetchBlogs();
      setBlogData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const [imagedata, setimagedata] = useState([]);
  const getImages = async () => {
    try {
      const response = await fetchImages();
      setimagedata(response);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getImages();
    getBlogs();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Onkar Group - Leading Sugar Industry with Innovation & Sustainability
        </title>
        <meta
          name="description"
          content="Welcome to Onkar Group, a pioneer in Maharashtra’s sugar industry with nine production units across the state. We specialize in sustainable sugar production, clean energy, and industrial alcohol, driven by innovation and community impact.
"
        />
        <meta
          name="keywords"
          content="Onkar Group, Maharashtra Sugar Industry, Onkar Sakhar Karkhana, Sugar Production, Sustainable Sugar, Clean Energy, Industrial Alcohol, Sugar Factories Pune, Sugar Manufacturing India, Community Impact
"
        />
         <link rel="canonical" href="https://onkarsugars.com" />
      </Helmet>
      <HeroSection />

      {/* <div className="about-section parent">
        <div className="about-section-cont cont">
          <div className="about-section-cont-left">
            <Tilt
              options={defaultOptions}
              className="img-box bg-img-cover"
            ></Tilt>
          </div>
          <div className="about-section-cont-right">
            <h2>About Onkar</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
              similique iusto beatae deserunt eaque iste, modi natus praesentium
              quis dolore doloremque voluptatem aspernatur ea repellat provident
              voluptatibus non! Aut, in?
            </p>

            <Link className="btn" to="/about">
              Know More
            </Link>
          </div>
        </div>
      </div>

       <ProductSwiperSection/> */}

      <TwoColSec
        bgtop={bgtop2}
        padding=""
        row=""
        bg={about3}
        subheading="ABOUT US "
        subdescription="Welcome to Onkar Group, a leading force in Maharashtra's flourishing sugar industry. Headquartered in Pune, we are a network of distinguished companies, with nine strategically located production units across the state. Our commitment to quality and innovation has built a legacy of excellence at Onkar Sugars. We maintain the highest production standards while continuously evolving our practices to meet the global market’s ever-changing demands. With a strong focus on sustainable growth, we strengthen our industry leadership and positively impact the communities we serve.
        "
        btn="READ MORE"
        link="/about"
      />
      <TwoColSec
        background="var(--cream2)"
        padding=""
        row="row-reverse"
        bg={about4}
        subheading="Factories"
        subdescription="We operate a total of nine factory units, led by our main
                  facility, Onkar Sakhar Karkhana Pvt. Ltd., located in
                  Chandapuri, Tal. Malshiras, Dist. Solapur. Supported by our
                  subsidiary units, each with specialised capabilities, we
                  uphold a proud legacy of excellence in sugar manufacturing.
                  Through our expertise and continuous modernisation efforts, we
                  achieve impressive daily crushing capacities, maximising
                  productivity while maintaining a steadfast commitment to
                  operational excellence.
        "
        btn="Know More"
        link="/factories"
      />
      <TwoColSec
        bgtop={bgtop2}
        padding=""
        row=""
        bg={product}
        subheading="Products"
        subdescription="Onkar Sakhar Karkhana Pvt Ltd goes beyond just sugar
                  production. We deliver a range of products that cater to
                  agricultural and industrial needs. Our core business of sugar
                  production is supported by a commitment to sustainability
                  through cogeneration plants that produce clean energy. Our
                  reach broadens by offering high-quality industrial alcohol
                  produced in their distillery units.
        "
        btn="Know More"
        link="/products"
      />

      <div className="testimoneals-parent parent">
        <div className="testimoneals-tag-cont cont">
          <div className="test-tag">
            <h2 className="heading" data-aos="fade-up">
              What Our Customers Say
            </h2>
          </div>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={false}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div
                className="testimoneal-cont cont"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="testimoneal-para">
                  <p className="test-p">
                    As an employee at Onkar Sakhar Karkhana Pvt Ltd.'s Unit 1,
                    I've witnessed their dedication to sustainable practices
                    firsthand. They truly care about their workers and the
                    environment
                  </p>
                </div>
                <div className="desgintion">
                  {/* <img src={dummyImg} alt="" /> */}
                  <div className="name">
                    <h5>Prem Deshmukh</h5>
                    <p> Factory Worker</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimoneal-cont cont">
                <div className="testimonel-content"></div>
                <div className="testimoneal-para">
                  <p className="test-p">
                    Supplying sugarcane to Onkar Sakhar Karkhana Pvt Ltd. has
                    been a rewarding experience. Their professionalism and fair
                    practices make them stand out
                  </p>
                </div>
                <div className="desgintion">
                  {/* <img src={dummyImg} alt="" /> */}
                  <div className="name">
                    <h5>Mohan Singh</h5>
                    <p>Sugarcane Supplier</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimoneal-cont cont">
                <div className="testimonel-content"></div>
                <div className="testimoneal-para">
                  <p className="test-p">
                    I've had the pleasure of partnering with Onkar Sakhar
                    Karkhana Pvt Ltd. Their dedication to excellence and
                    innovation is inspiring.
                  </p>
                </div>
                <div className="desgintion">
                  {/* <img src={dummyImg} alt="" /> */}
                  <div className="name">
                    <h5>Vikram Singhania</h5>
                    <p> Business Partner</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Home;
