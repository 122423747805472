import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import bgtop from '../Assets/self-info-bg.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../Styles/socialres.scss";
import img from "../Assets/plant.webp";
import TwoColSec from "../Comps/TwoColSec";
// aos
import AOS from "aos";
import "aos/dist/aos.css";
import Topbar from "../Comps/Topbar";
import { Helmet } from "react-helmet";

function SocialRes() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [mobile, setMobile] = useState(false);
  const [compost, setCompost] = useState(true);
  const [wasteManagement, setwasteManagement] = useState(false);
  const [community, setCommunity] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [sutainable, setSutainable] = useState(false);
  const [Stakeholder, setStakeholder] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 800);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const compostToggle = () => {
    setCompost(true);
    setwasteManagement(false);
    setCommunity(false);
    setEmployee(false);
    setSutainable(false);
    setStakeholder(false);
  };

  const wasteToggle = () => {
    setwasteManagement(true);
    setCommunity(false);
    setEmployee(false);
    setSutainable(false);
    setStakeholder(false);
    setCompost(false);
  };

  const communityToggle = () => {
    setCommunity(true);
    setEmployee(false);
    setSutainable(false);
    setStakeholder(false);
    setCompost(false);
    setwasteManagement(false);
  };

  const employeeToggle = () => {
    setCommunity(false);
    setEmployee(true);
    setSutainable(false);
    setStakeholder(false);
    setCompost(false);
    setwasteManagement(false);
  };
  const sutainableToggle = () => {
    setCommunity(false);
    setEmployee(false);
    setSutainable(true);
    setStakeholder(false);
    setCompost(false);
    setwasteManagement(false);
  };
  const stakeHolderToggle = () => {
    setCommunity(false);
    setEmployee(false);
    setSutainable(false);
    setStakeholder(true);
    setCompost(false);
    setwasteManagement(false);
  };
  const links = [
    {
      link_path: "/",
      link_name: "Home",
    },
    {
      link_path: "/social_responsibility",
      link_name: "Social Responsibility",
    }]

  return (
    <>
    <Helmet>
        <title>Social Responsibility - Onkar Sakhar Karkhana Pvt Ltd</title>
        <meta name="description" content="Discover the initiatives taken by Onkar Sakhar Karkhana Pvt Ltd to promote social and environmental responsibility, including compost fertilisers, waste management, community engagement, and more." />
        <meta name="keywords" content="social responsibility, compost fertilisers, waste management, community engagement, sustainable practices, Onkar Sakhar Karkhana, environmental responsibility" />
        <link rel="canonical" href="https://onkarsugars.com/social_responsibility" />
      </Helmet>

<Topbar links={links} page_top_name = "Social Responsibility"/>
      <TwoColSec
        bgtop={bgtop}
        padding="50px 10px 50px 10px"
        row=""
        bg={img}
        subheading="Our Commitment to Community and Environment"
        subdescription="At Onkar Sakhar Karkhana Pvt Ltd, we are deeply committed to our social and environmental responsibilities. We believe in contributing positively to the communities and environment in which we operate. Onkar Sakhar Karkhana Pvt Ltd remains steadfast in its commitment to social responsibility, sustainability, and ethical business practices. We believe that by acting responsibly and ethically, we can create a better world for current and future generations.


                "
        btn=""
      />
      <div className="social-res-parent parent">
        <div className="overlay-img ">
          <div className="social-res-cont cont">

            {mobile && (
              <div className="social-res-swiper">
                <div
                  className="our-intivaties"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <h2>OUR INITIATIVES</h2>
                  <p>
                    Discover how we're making a positive impact through our social
                    and environmental initiatives.
                  </p>
                </div>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  pagination={false}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide className="social-slides">
                    <div className="social-img">
                      <div className="img-section compost-ferti-swi-img bg-img-cover"></div>
                    </div>
                    <div className="social-content">
                      <h3>Compost Fertilisers</h3>
                      <p>
                        Our compost fertiliser projects aim to recycle organic
                        waste from our operations and neighbouring communities. By
                        converting this waste into nutrient-rich compost, we not
                        only minimise waste but also promote organic farming
                        practices, enhance soil fertility, and support local
                        agricultural communities.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="social-slides">
                    <div className="social-img">
                      <div className="img-section waste-mange-swi-img bg-img-cover"></div>
                    </div>
                    <div className="social-content">
                      <h3>Waste Management</h3>
                      <p>
                        We have implemented comprehensive waste management
                        practices across our facilities. By reducing, recycling,
                        and responsibly disposing of waste, we minimise
                        environmental pollution and strive to create cleaner and
                        healthier environments for the communities surrounding our
                        operations.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="social-slides">
                    <div className="social-img">
                      <div className="img-section community-eng-swi-img bg-img-cover"></div>
                    </div>
                    <div className="social-content">
                      <h3>Community Engagement</h3>
                      <p>
                        We actively engage with local communities through various
                        outreach programs and initiatives. From providing
                        educational and healthcare support to organising skill
                        development workshops and livelihood enhancement programs,
                        we are dedicated to empowering and uplifting the
                        communities we serve.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="social-slides">
                    <div className="social-img">
                      <div className="img-section employee-wel-swi-img bg-img-cover"></div>
                    </div>
                    <div className="social-content">
                      <h3>Employee Welfare</h3>
                      <p>
                        The well-being of our employees is of utmost importance to
                        us. We provide a safe and conducive working environment,
                        offer training and development opportunities, and ensure
                        fair and equitable employment practices. By prioritising
                        employee welfare, we foster a culture of engagement,
                        productivity, and mutual respect.
                      </p>
                    </div>
                  </SwiperSlide>
                  
                  <SwiperSlide className="social-slides">
                    <div className="social-img">
                      <div className="img-section sustainable-prac-swi-img bg-img-cover"></div>
                    </div>
                    <div className="social-content">
                      <h3> Sustainable Practices</h3>
                      <p>
                        We are committed to adopting sustainable practices
                        throughout our operations. From water conservation and
                        energy efficiency measures to biodiversity conservation
                        and responsible sourcing, we strive to minimise our
                        environmental footprint and uphold ecological integrity.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="social-slides">
                    <div className="social-img">
                      <div className="img-section stakeholder-swi-img bg-img-cover"></div>
                    </div>
                    <div className="social-content">
                      <h3>Stakeholder Engagement</h3>
                      <p>
                        We actively engage with our stakeholders, including
                        customers, suppliers, regulators, and local communities,
                        to ensure transparency, accountability, and mutual
                        understanding. By fostering open communication and
                        collaboration, we build trust and long-term relationships
                        based on shared values and objectives.
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            )}
            <div className="socail-res-section">


              <div className="social-cont-top">
                <div
                  className="our-intivaties"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <h2 className="heading">OUR INITIATIVES</h2>
                  <p>
                    Discover how we're making a positive impact through our social
                    and environmental initiatives.
                  </p>
                </div>
              </div>
              <div className="social-page-divider">
                <div
                  className="social-res-sidebar-left"
                  data-aos="fade-right"
                  data-aos-duration="900"
                >
                  <div className="social-button">
                    <button onClick={compostToggle} className={!compost ? "social-btn" : "active-btn"} >Compost Fertilisers</button>
                    <button onClick={wasteToggle} className={!wasteManagement ? "social-btn" : "active-btn" }>Waste Management</button>
                    <button onClick={communityToggle} className={!community ? "social-btn" : "active-btn" }>Community Engagement</button>
                    <button onClick={employeeToggle} className={!employee ? "social-btn" : "active-btn" }>Employee Welfare</button>
                    <button onClick={sutainableToggle} className={!sutainable ? "social-btn" : "active-btn" }>
                      Sustainable Practices
                    </button>
                    <button onClick={stakeHolderToggle} className={!Stakeholder ? "social-btn" : "active-btn" }>
                      Stakeholder Engagement
                    </button>
                  </div>
                </div>
                <div className="social-res-content-right">
                  {compost && (
                    <div
                      className="solar-power-plants"
                      data-aos="fade-up"
                      data-aos-duration="900"
                    >
                      <div className="solar-img bg-img-cover"></div>
                      <div className="solar-tag">
                        <h2>Compost Fertilisers</h2>
                      </div>
                      <div className="solar-info">
                        <p>
                          Our compost fertiliser projects aim to recycle organic
                          waste from our operations and neighbouring communities. By
                          converting this waste into nutrient-rich compost, we not
                          only minimise waste but also promote organic farming
                          practices, enhance soil fertility, and support local
                          agricultural communities.
                        </p>
                      </div>
                    </div>
                  )}
                  {wasteManagement && (
                    <div
                      className="solar-power-plants"
                      data-aos="fade-up"
                      data-aos-duration="900"
                    >
                      <div className="solar-img waste-mange-img bg-img-cover"></div>
                      <div className="solar-tag">
                        <h2>Waste Management</h2>
                      </div>
                      <div className="solar-info">
                        <p>
                          We have implemented comprehensive waste management
                          practices across our facilities. By reducing, recycling,
                          and responsibly disposing of waste, we minimise
                          environmental pollution and strive to create cleaner and
                          healthier environments for the communities surrounding our
                          operations.
                        </p>
                      </div>
                    </div>
                  )}
                  {community && (
                    <div
                      className="solar-power-plants"
                      data-aos="fade-up"
                      data-aos-duration="900"
                    >
                      <div className="solar-img community-eng-img bg-img-cover"></div>
                      <div className="solar-tag">
                        <h2>Community Engagement</h2>
                      </div>
                      <div className="solar-info">
                        <p>
                          We actively engage with local communities through various
                          outreach programs and initiatives. From providing
                          educational and healthcare support to organising skill
                          development workshops and livelihood enhancement programs,
                          we are dedicated to empowering and uplifting the
                          communities we serve.
                        </p>
                      </div>
                    </div>
                  )}
                  {employee && (
                    <div
                      className="solar-power-plants"
                      data-aos="fade-up"
                      data-aos-duration="900"
                    >
                      <div className="solar-img employee-welfare-img bg-img-cover"></div>
                      <div className="solar-tag">
                        <h2>Employee Welfare</h2>
                      </div>
                      <div className="solar-info">
                        <p>
                          The well-being of our employees is of utmost importance to
                          us. We provide a safe and conducive working environment,
                          offer training and development opportunities, and ensure
                          fair and equitable employment practices. By prioritising
                          employee welfare, we foster a culture of engagement,
                          productivity, and mutual respect.
                        </p>
                      </div>
                    </div>
                  )}
                  {sutainable && (
                    <div
                      className="solar-power-plants"
                      data-aos="fade-up"
                      data-aos-duration="900"
                    >
                      <div className="solar-img sustainable-prac-img bg-img-cover"></div>
                      <div className="solar-tag">
                        <h2>Sustainable Practices</h2>
                      </div>
                      <div className="solar-info">
                        <p>
                          We are committed to adopting sustainable practices
                          throughout our operations. From water conservation and
                          energy efficiency measures to biodiversity conservation
                          and responsible sourcing, we strive to minimise our
                          environmental footprint and uphold ecological integrity.
                        </p>
                      </div>
                    </div>
                  )}
                  {Stakeholder && (
                    <div
                      className="solar-power-plants"
                      data-aos="fade-up"
                      data-aos-duration="900"
                    >
                      <div className="solar-img stakeholder-eng-img bg-img-cover"></div>
                      <div className="solar-tag">
                        <h2>Stakeholder Engagement</h2>
                      </div>
                      <div className="solar-info">
                        <p>
                          We actively engage with our stakeholders, including
                          customers, suppliers, regulators, and local communities,
                          to ensure transparency, accountability, and mutual
                          understanding. By fostering open communication and
                          collaboration, we build trust and long-term relationships
                          based on shared values and objectives.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialRes;
