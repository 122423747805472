import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
// aos
import AOS from "aos";
import "aos/dist/aos.css";

import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Header from "./Comps/Header";
import Footer from "./Comps/Footer";
import Factories from "./Pages/Factories";
import Products from "./Pages/Products";
import SocialRes from "./Pages/SocialRes";
import Founders from "./Pages/Founders";
import Erropage from "./Comps/Erropage";
import Privacy from "./Pages/Privacy";
import Cookies from "./Pages/Cookies";
import Environment from "./Pages/Environment";
import Blog from "./Pages/Blog";
import BlogView from "./Pages/BlogView";
import Gallerypage from "./Pages/Gallerypage";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./Comps/ScrollToTop";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration in milliseconds
    });
  }, []);
  
    const goToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  
  return (
    <div className="App">
      <BrowserRouter>
      <PageAnalytics />
        <Header />
       <ScrollToTop/>
        <Toaster position="top-right" reverseOrder={false} />
        <Routes>
          <Route path="*" element={<Erropage />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/founders" element={<Founders />} />
          <Route path="/factories" element={<Factories />} />
          <Route path="/products" element={<Products />} />
          <Route path="/social_responsibility" element={<SocialRes />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/terms&condition" element={<Cookies />} />
          <Route path="/environment" element={<Environment />} />
          {/* <Route path="/blogs" element={<Blog />} />
          <Route path="/blogs/:id" element={<BlogView />} /> */}
          <Route path="/gallery" element={<Gallerypage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
// Component to handle Google Analytics page tracking
function PageAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // Track page view with Google Analytics on route change
    if (window.gtag) {
      window.gtag("config", "G-0SS10R52DT", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]); // This runs every time the route changes

  return null; // This component doesn't render anything
}
export default App;
