import React, { useEffect, useState } from "react";
import "../Styles/contact.scss";
import { Link, useLocation } from "react-router-dom";
// icons
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

// aos
import AOS from "aos";
import "aos/dist/aos.css";
import { submitContactForm } from "../services/service";
import axios from "axios";
import toast from "react-hot-toast";
import Topbar from "../Comps/Topbar";
import { Helmet } from "react-helmet";

function Contact() {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  useEffect(() => {
    AOS.init();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitContactForm(contactData);
      console.log("submitted");
      setContactData({
        name: "",
        email: "",
        number: "",
        message: "",
      });
      toast.success("Thank you for contacting us.")
    } catch (err) {
      console.log(err);
    }
  };

  const links = [
    {
      link_path: "/",
      link_name: "Home",
    },
    {
      link_path: "/contact",
      link_name: "contact",
    }]

  return (
    <>
    <Helmet>
  <title>Contact Us - Onkar Sakhar Karkhana</title>
  <meta name="description" content="Get in touch with Onkar Sakhar Karkhana for inquiries, support, or collaboration opportunities. Reach us via phone, email, or visit our office." />
  <meta name="keywords" content="contact, Onkar Sakhar Karkhana, customer support, inquiries, business contact, collaboration, email, phone" />
  <link rel="canonical" href="https://onkarsugars.com/contact" />
</Helmet>

      {/* <div className="contact-sec bg-img-cover parent">
        <div className="overlay">
          <div className="contact-cont cont">
          <div data-aos="fade-up" data-aos-delay="100" className="page_header">
              {" "}
              <h1 class="textName">Contact Us</h1>{" "}
            </div>
          </div>
        </div>
      </div> */}
 <Topbar links={links} page_top_name = "Contact Us"/>
      
      <div className="contact-content parent" >
        <div className="overlay-img-second">
          <div className="contact-content-cont cont" data-aos="fade-up"  data-aos-delay="100">
            <div className="contact-left">
              <form action="#" onSubmit={handleSubmit}>
                <input
                
                  type="text"
                  value={contactData.name}
                  placeholder="Name"
                  required
                  onChange={(e) => {
                    setContactData({ ...contactData, name: e.target.value });
                  }}
                />
                <input
                
                  type="email"
                  value={contactData.email}
                  placeholder="Email"
                  onChange={(e) => {
                    setContactData({ ...contactData, email: e.target.value });
                  }}
                  required
                />
                <input
                
                  type="tel"
                  value={contactData.number}
                  placeholder="Number"
                  required
                  onChange={(e) => {
                    setContactData({ ...contactData, number: e.target.value });
                  }}
                />
                <textarea
               
                  onChange={(e) => {
                    setContactData({ ...contactData, message: e.target.value });
                  }}
                  value={contactData.message}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Message"
                  required
                ></textarea>
                <button className="btn" type="submit" >
                  Submit
                </button>
              </form>
            </div>
            <div className="contact-right">
              {/* <h3>Let's Make Great Beginnings!</h3> */}
              <h3 >Join Forces With Onkar - Let's Achieve More Together</h3>
              <p >
                Please feel free to reach out to us using the contact
                information below.
              </p>
              <div className="mail-id" >
                <span>
                  {" "}
                  <IoIosMail />{" "}
                </span>
                <p>
                  <Link to="mailto:onkarsakharkpl.adm@gmail.com" target="blank" >
                    onkarsakharkpl.adm@gmail.com
                  </Link>
                </p>
              </div>
              <div className="address" >
                <span>
                  {" "}
                  <FaLocationDot />{" "}
                </span>
                <p>
                  {" "}
                  <Link
                    to="https://maps.app.goo.gl/GWKkKRfiiFGQdkZ47"
                    target="blank"
                  >
                    Office no. 301, Onkar Sakhar Karkhana, 3rd Floor, Fortune
                    Business House, Sakal Nagar, Aundh, Pune
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
