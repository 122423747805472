import React, { useEffect } from "react";
import "../Styles/twoColSec.scss";

import { Link } from "react-router-dom";
// import SecIndicator from './SecIndicator'

// aos
import AOS from "aos";
import "aos/dist/aos.css";

function TwoColSec(props) {
  
  useEffect(() => {
    AOS.init();
  },[]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="parent twoColSec"
      
      style={{ backgroundColor: `${props.background}`, padding: `${props.padding}` , backgroundImage: `url(${props.bgtop})` }}
    >
      <div
        className="twoColSec-cont cont"
        style={{ flexDirection: `${props.row}` }}
        data-aos="fade-up"  data-aos-delay="200"
      >
        <div className="sec-left">
          <div
            className="img-box bg-img-cover"
            
            
            style={{ background: `url(${props.bg})` }}
          ></div>
        </div>
        <div
          className="sec-right"
          
       
        >
          {/* {props.secind && <SecIdata-aos="fade-right" data-aos-duration="3000"ndicator text={props.secind} />} */}

          <h2 >{props.subheading}</h2>
          <p >{props.subdescription}</p>

          {props.children}

          {props.btn && (
            <Link to={props.link} className="btn" onClick={ goToTop} >
              {" "}
              {props.btn}{" "}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default TwoColSec;
