import React, { useState, useEffect } from "react";
import "../Styles/about.scss";
import img from "../Assets/raw/bg5.webp";
import bgtop from "../Assets/self-info-bg.png";

import img2 from "../Assets/raw/bg2.webp";
import TwoColSec from "../Comps/TwoColSec";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Founders from "./Founders";
import Topbar from "../Comps/Topbar";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    AOS.init();
  }, []);

  const links = [
    {
      link_path: "/",
      link_name: "Home",
    },
    {
      link_path: "/about",
      link_name: "About",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          About Onkar Group - Pioneers in Sustainable Sugar Production &
          Innovation
        </title>
        <meta
          name="description"
          content="Discover the story of Onkar Group, a leader in Maharashtra’s sugar industry. With 800,000 farmers, 8,000 employees, and cutting-edge projects like distilleries, cogeneration, and Bio CNG, Onkar Sugars drives sustainable growth and community development.
"
        />
        <meta
          name="keywords"
          content="Onkar Group, Onkar Sakhar Karkhana, Sustainable Sugar Production, Maharashtra Sugar Industry, Bio CNG, Cogeneration Project, Distillery Project, Compost Fertiliser, Renewable Energy, Agricultural Sustainability, Sugar Industry Leadership
"
        />
        <link rel="canonical" href="https://onkarsugars.com/about" />

      </Helmet>
      <Topbar links={links} page_top_name="About Us" />

      {/* founder our pillar */}
      <Founders />

      {/* two column */}
      <TwoColSec
        background="var(--cream2)"
        row=""
        bg={img}
        subheading="OUR STORY "
        subdescription="At Onkar Sugar, we are proud to be a thriving community that includes 800,000 farmers and 200,000 harvesting labourers who play a pivotal role in harvesting and transporting sugarcane to our factories. Additionally, our workforce of 8,000 dedicated employees, positions Onkar Sugars as one of the largest sugar producers in Maharashtra. With deep-rooted expertise in agriculture and farming, spanning centuries, we understand our soil, farmers, and produce better than anyone, which allows us to maintain the highest standards in the industry.
Our journey began in the lush landscapes of Chandapuri, Tal. Malshiras, Dist. Solapur, where Onkar Sakhar Karkhana Pvt. Ltd. was established. The fertile region, with abundant irrigation from a one-side canal system, provided the perfect environment for sugarcane cultivation. This proximity to natural resources laid the foundation for our venture and propelled us toward becoming specialists in sugar production.

        


                "
        btn=""
      />

      <TwoColSec
        bgtop={bgtop}
        row="row-reverse"
        bg={img2}
        subheading=""
        subdescription="Starting with an initial crushing capacity of 2,500 TCD (Tons of Cane Crushed per Day), we have steadily expanded. Through strategic investments and modernisation efforts, we now operate with a crushing capacity of 7.2 million metric tons annually. This remarkable growth reflects our unwavering dedication to operational excellence and industry leadership.
Today, Onkar Sakhar Karkhana Pvt. Ltd. stands as a symbol of resilience and ingenuity. Every challenge we've faced has strengthened our commitment to innovation, and we continue to drive forward with a vision for nationwide expansion and even greater achievements. Our story is one of progress, perseverance, and the pursuit of excellence in every aspect of sugar production.

        
                


                "
        btn=""
      />

      {/* ...........//Personality Section//............. */}

      <div className="personality parent">
        <div className="overlay-img ">
          <div className="personality-container cont">
            <h2 className="heading">OUR PROJECTS</h2>
            <p data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
              The projects undertaken by our industry reflect our commitment to
              innovation, sustainability, and community development, all woven
              into the fabric of our operations. Here's an overview of our
              noteworthy projects:
            </p>

            <Swiper
              paceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={false}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="personality_twobox_cont">
                  <div
                    className="personality_img personality_img-1 bg-img-cover"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <div class="image bg-img-cover"></div>
                  </div>
                  <div
                    className="personality_content"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                  >
                    <h3>
                      {" "}
                      Distillery Project - 1,100 KLPD{" "}
                      <span className="line"></span>
                    </h3>
                    <p>
                      Our distillery project, boasting a capacity of 1,100 KLPD
                      (kilo litres per day), stands as a testament to our
                      dedication to diversification and value addition. Through
                      this venture, we leverage state-of-the-art technology to
                      produce high-quality alcohol, catering to diverse
                      industrial and commercial needs while ensuring optimal
                      resource utilisation and environmental responsibility.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="personality_twobox_cont">
                  <div
                    className="personality_img Cogeneration-img bg-img-cover"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <div class="image bg-img-cover"></div>
                  </div>
                  <div
                    className="personality_content"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                  >
                    <h3>
                      {" "}
                      Cogeneration Project - 120 MW{" "}
                      <span className="line"></span>
                    </h3>
                    <p>
                      Our cogeneration project, generating an impressive 120 MW
                      (Megawatts) of power, epitomises our commitment to
                      sustainability and self-sufficiency. By harnessing the
                      power of renewable resources and utilising waste
                      by-products from our operations, we not only meet our
                      energy needs but also contribute surplus electricity to
                      the grid, fostering a greener, more sustainable future.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="personality_twobox_cont">
                  <div
                    className="personality_img Bio-img bg-img-cover"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <div class="image bg-img-cover"></div>
                  </div>
                  <div className="personality_content">
                    <h3>
                      {" "}
                      Bio CNG - 36 TPH<span className="line"></span>
                    </h3>
                    <p>
                      In line with our ethos of embracing renewable energy
                      sources, our Bio CNG project, with a capacity of 36 TPH
                      (Tons per Hour), signifies our dedication to reducing
                      carbon emissions and promoting environmental conservation.
                      By converting organic waste into clean, renewable energy,
                      we play a pivotal role in mitigating climate change and
                      fostering a cleaner, greener tomorrow.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="personality_twobox_cont">
                  <div
                    className="personality_img  Compost-img bg-img-cover"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <div class="image bg-img-cover"></div>
                  </div>
                  <div className="personality_content">
                    <h3>
                      {" "}
                      Compost Fertiliser - 30,000 MT{" "}
                      <span className="line"></span>
                    </h3>
                    <p>
                      Our compost fertiliser project, producing 30,000 metric
                      tonnes of organic fertiliser annually, underscores our
                      commitment to sustainable agriculture and soil health. By
                      recycling organic waste from our operations and
                      neighbouring communities, we not only minimise waste but
                      also enrich the soil with nutrient-rich compost,
                      empowering farmers and promoting eco-friendly agricultural
                      practices.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
