import React, { useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../../src/Styles/mapSection.scss';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

const greenIcon = new L.Icon({
  iconUrl: require('../Assets/icon-location.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconSize: [55, 56],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [85, 50],
  className: 'green-marker',
 
  
  
});

const markers = [
  { id: 'factory1', position: [17.728619983186373, 74.96048951110183], label: 'Factory-1 : Onkar Sakhar Karkhana Pvt. Ltd.' },
  { id: 'factory2', position: [18.408778191283968, 76.56104511259949], label: 'Factory-2 : Onkar Sakhar Karkhana Pvt. Ltd.' },
  { id: 'factory3', position: [16.704682917186254, 74.24212857953833], label: 'Factory-3 : Onkar Sugar and Distillery Power Pvt. Ltd.' },
  { id: 'factory4', position: [18.59325246378507, 74.7986356537343], label: 'Factory-4 : Gauri Sugar and Distilleries Pvt. Ltd.' },
  { id: 'factory5', position: [17.39834381040354, 75.97954885882442], label: 'Factory-5 : Onkar Power Corporations Pvt. Ltd.' },
  { id: 'factory6', position: [18.128261416185182, 75.4983322374803], label: 'Factory-6 : Onkar Sugar Corporations Pvt. Ltd.' },
  { id: 'factory7', position: [18.823333018031278, 74.44275721180018], label: 'Factory-7 : Onkar Sugar and Allied Industries Pvt. Ltd.' },
  { id: 'factory8', position: [18.99046334126749, 75.75206187367758], label: 'Factory-8 : Onkar Sakhar Karkhana Pvt. Ltd.' },
  { id: 'factor9', position: [21.371114800949197, 74.24063498970543], label: 'Factory-9 : Onkar Sugar and Allied Industries Pvt. Ltd.' },
];

function scrollToSection(id) {
  const section = document.getElementById(id);
  if (section) {
    console.log("Scrolling to: ", id); // This logs the ID to help debug
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  } else {
    console.error("Section not found for ID:", id); // Error log if section is not found
  }
}

function ChangeMapCenter({ position }) {
  const map = useMap();
  map.setView(position, map.getZoom(), { animate: true });
  return null;
}

function MapSection() {
  const [selectedMarker, setSelectedMarker] = useState(null);

  return (
    <div className="mapsection parent" style={{ height: '400px', width: '100%' }} >
      <div className="map-section-container container">
      <MapContainer
        center={[17.728619983186373, 74.96048951110183]}
        zoom={13}
        style={{ height: '100%', width: '100%' }}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {selectedMarker && <ChangeMapCenter position={selectedMarker} />}
        {markers.map((marker, index) => (
          <Marker
          
            key={index}
            position={marker.position}
            icon={greenIcon}
            interactive={true}
            eventHandlers={{
              click: () => {
                setSelectedMarker(marker.position);
                scrollToSection(marker.id);
              },
            }}
          >
            <Tooltip
            
              direction="right"
              permanent
              offset={[30, -10]}
              interactive={true} // Allow clicking on tooltip
            >
              <span
                role="button"
                data-aos="zoom-in"
                data-aos-delay="100"
                tabIndex={0}
                onClick={() => {
                  setSelectedMarker(marker.position);
                  scrollToSection(marker.id);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setSelectedMarker(marker.position);
                    scrollToSection(marker.id);
                  }
                }}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                {marker.label}
              </span>
            </Tooltip>
          </Marker>
        ))}
      </MapContainer>

      </div>
      
      
    </div>
  );
}

export default MapSection;
