import React, { useEffect } from "react";
import "../Styles/factory2.scss";
import bgtop2  from '../Assets/self-info-bg.png'

import factoryImg from "../Assets/raw/bg5.webp";
import TwoColSec from "../Comps/TwoColSec";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import MapSection from "../Comps/MapSection";
import { ImLocation } from "react-icons/im";
import Topbar from "../Comps/Topbar";
import Factory from "../Comps/factory/Factory";

import fac1 from "../../src/Assets/factory/fac1.webp";
import fac2 from "../../src/Assets/factory/fac2.webp";
import fac3 from "../../src/Assets/factory/fac3.webp";
import fac4 from "../../src/Assets/factory/fac4.webp";
import fac5 from "../../src/Assets/factory/fac5.webp";
import fac6 from "../../src/Assets/factory/fac6.webp";
import fac7 from "../../src/Assets/factory/fac7.webp";
import fac8 from "../../src/Assets/factory/fac8.webp";
import fac9 from "../../src/Assets/factory/fac9.webp";
import Factory2 from "../Comps/Factory2";
import { Helmet } from "react-helmet";

function Factories() {
  const links = [
    {
      link_path: "/",
      link_name: "Home",
    },
    {
      link_path: "/factories",
      link_name: "Factories",
    },
  ];
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    <Helmet>
  <title>Onkar Group of Factories | Sustainable Sugar Production & Energy</title>
  <meta name="description" content="Explore Onkar Group's factories located across Maharashtra, with a focus on sugar production, distilleries, cogeneration, and renewable energy projects." />
  <meta name="keywords" content="Onkar Group, Onkar Sakhar Karkhana, sugar production, renewable energy, cogeneration, distilleries, bio CNG, compost fertilizer, sugar factory, Maharashtra, sustainable operations, power generation, energy efficiency, sugar refining, organic fertilizer, eco-friendly projects" />
  <link rel="canonical" href="https://onkarsugars.com/factories" />
</Helmet>
      <Topbar links={links} page_top_name="Factories" />

      <TwoColSec
        bgtop={bgtop2}
        row=""
        bg={factoryImg}
        subheading="Onkar Group Of Factories
        "
        subdescription="Onkar Sakhar Karkhana Pvt. Ltd., located in Chandapuri, Tal. Malshiras, Dist. Solapur, is a private limited Indian non-government company incorporated in India. Situated near Malshiras, the company benefits from an area rich in irrigation facilities, particularly a one-side canal irrigation system, ensuring abundant water availability essential for sugarcane cultivation.
The company operates nine factories dedicated to various functions, including sugar refining, distilleries, and power generation. Over the years, the total crushing capacity has grown significantly, increasing from an initial 2,500 TCD (Tons of Cane Crushed per Day) to an impressive 7.2 million metric tons annually.
The output from these factories plays a critical role in cementing Maharashtra’s position as the largest sugar producer in the country. Let’s explore each of these factories to understand their contributions in greater detail.



                "
        btn=""
      />
      {/* map ----------------------------------- */}

      <MapSection />
      {/* ...........//Personality Section//............. */}

      <Factory2 />


















   
      {/* <div className="factory-parent factory-section parent">
        <div className="overlay-img overlay-img-for-factory bg-img-cover">
          <div className="factory-container cont">
            <h2
              className="heading"
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="900"
            >
              FACTORIES
            </h2> */}

       

     {/* <div className="factory" id="factory1">
              <div
                className="factory_img factories-img-first  "
                data-aos="fade-up"
             
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
             
              <div
                className="factory_content"
                data-aos="fade-up"
                
                data-aos-duration="1000"
              >
                 <h3 className="factory-number">Factory 1</h3>
                <h3>Onkar Sakhar Karkhana Pvt. Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/39vaQrupC8M6eaE17"
                  target="_blank"
                  className="address"
                >
               
                  Chandapuri, Taluka Malshiras, District Solapur-413310
                </a>
                <p>
                  This unit boasts a sugar daily crushing capacity of 6500 TCD,
                  accompanied by a distillery producing 150 KLPD and a
                  cogeneration facility generating 20 MW of power. Situated in
                  Chandapuri, Tal. Malshiras, Dist. Solapur, the unit also hosts
                  a bio CNG project with a capacity of 12 TPH, a compost
                  fertiliser project producing 200 MT, and an Indian Oil petrol
                  pump.
                </p>
              </div>
            </div> 

      {/* factory 2 */}
{/* 
    <div
              className="factory"
              id="factory2"
              style={{ flexDirection: "row-reverse" }}
            >
              <div
                className="factory_img factories-img-second  bg-img-cover"
                data-aos="fade-up"
             
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
              
              <div
                className="factory_content"
                data-aos="fade-up"
        
                data-aos-duration="1000"
              >
                <h3 className="factory-number">Factory 2</h3>
                <h3> Onkar Sakhar Karkhana Pvt. Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/dvKeZ6QcAEetcYd67"
                  target="_blank"
                  className="address"
                >
                 
                  Ambulga, Taluka Nilanga, District Latur-413530
                </a>
                <p>
                  This unit features a sugar crushing capacity of 4500 TCD,
                  supported by a distillery producing 150 KLPD. Additionally, it
                  includes a diesel pump, a CNG generation project, and an
                  organic fertiliser project, ensuring a comprehensive and
                  sustainable operational setup.
                </p>
              </div>
            </div>  */}

      {/* factory 3 */}

      {/* <div className="factory" id="factory3">
              <div
                className="factory_img factories-img-third  bg-img-cover"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
              <div
                className="factory_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <h3 className="factory-number">Factory 3</h3>
                <h3> Onkar Sugar and Distillery Power Pvt. Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/aQ6uvyqoAB96rG2x9"
                  target="_blank"
                  className="address"
                >
                
                  Farale, Taluka Radhanagari, District Kolhapur-416231
                </a>
                <p>
                  This unit is equipped with a sugar crushing capacity of 2500
                  TCD, complemented by a cogeneration facility generating 20 MW
                  of electricity, ensuring efficient energy utilisation and
                  sustainability. The integrated setup supports streamlined
                  operations and long-term productivity.
                </p>
              </div>
            </div>  */}

      {/* factory 4 */}

     {/* <div
              className="factory"
              id="factory4"
              style={{ flexDirection: "row-reverse" }}
            >
              <div
                className="factory_img factories-img-fourth  bg-img-cover"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
              <div
                className="factory_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <h3>Factory-4 : Gauri Sugar and Distilleries Pvt. Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/xNkoLtmiUqs8HFun7"
                  target="_blank"
                  className="address"
                >
                  
                  Hiradgaon, Taluka Shrigonda, District Ahmednagar-413701
                </a>
                <p>
                  This unit boasts a sugar crushing capacity of 10,000 TCD,
                  accompanied by a distillery producing 80 KLPD and a
                  cogeneration facility generating 40 MW of electricity.
                  Additionally, the setup includes a diesel pump, ensuring
                  seamless operations and energy efficiency.
                </p>
              </div>
            </div>  */}

      {/* factory 5 */}

      {/* <div className="factory" id="factory5">
              <div
                className="factory_img factories-img-fifth  bg-img-cover"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover">
                  <img src={fac1 } alt="" />
                </div>
              </div>
              <div
                className="factory_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <h3>Factory-5</h3>
                <h2>Onkar Power Corporations Pvt. Ltd.</h2>
                <a
                  href="https://maps.app.goo.gl/1NziCSkRFd2bVwtq6"
                  target="_blank"
                  className="address"
                >
                 
                  Tadwal, Taluka Akkalkot, District Solapur-413219
                </a>
                <p>
                  This unit features a sugar crushing capacity of 6500 TCD,
                  supported by a cogeneration facility generating 20 MW of
                  electricity. The integrated design ensures efficient energy
                  utilisation and sustainable operations.
                </p>
              </div>
            </div>  */}

      {/* factory 6 */}
     {/* <div
              className="factory"
              id="factory6"
              style={{ flexDirection: "row-reverse" }}
            >
              <div
                className="factory_img factories-img-six bg-img-cover"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
              <div
                className="factory_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <h3>Factory-6 : Onkar Sugar Corporations Pvt. Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/hF7eMFJJPyv8uj2V6"
                  target="_blank"
                  className="address"
                >
                 
                  Mhaisgaon, Taluka Madha, District Solapur-413208
                </a>
                <p>
                  This unit is equipped with a sugar crushing capacity of 6000
                  TCD, complemented by a distillery producing 250 KLPD and a
                  cogeneration facility generating 20 MW of electricity. The
                  setup ensures high efficiency and sustainable operations.
                </p>
              </div>
            </div>  */}

      {/* factory 7 */}
      {/* <div className="factory" id="factory7">
              <div
                className="factory_img factories-img-seven bg-img-cover"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
              <div
                className="factory_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <h3>Factory-7 : Onkar Sugar and Allied Industries Pvt.Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/fxcixis5LQFJePjB9"
                  target="_blank"
                  className="address"
                >
                
                  Deodaithan, Taluka Shrigonda, District Ahmednagar-413702
                </a>
                <p>
                  This unit includes a sugar crushing capacity of 2000 TCD,
                  supported by a cogeneration facility generating 5 MW of
                  electricity. Additionally, it features a diesel pump to
                  facilitate seamless and efficient operations.
                </p>
              </div>
            </div> */}

      {/* factory 8 */}

   {/* <div
              className="factory"
              id="factory8"
              style={{ flexDirection: "row-reverse" }}
            >
              <div
                className="factory_img factories-img-eight bg-img-cover"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
              <div
                className="factory_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <h3>Factory-8 : Onkar Sakhar Karkhana Pvt. Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/8oPsix8C3upATLhV8"
                  target="_blank"
                  className="address"
                >
               
                  Pangari, Taluka Parli, District Beed-431530
                </a>
                <p>
                  This unit boasts a sugar crushing capacity of 6500 TCD,
                  accompanied by a distillery producing 75 KLPD and a
                  cogeneration facility generating 20 MW of electricity. The
                  setup ensures efficient and sustainable operations.
                </p>
              </div>
            </div>  */}

      {/* factory 9 */}
    {/* <div className="factory" id="factor9">
              <div
                className="factory_img factories-img-nine bg-img-cover"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div class="image  bg-img-cover"></div>
              </div>
              <div
                className="factory_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000"
              >
                <h3>Factory-9 : Onkar Sugar and Allied Industries Pvt. Ltd.</h3>
                <a
                  href="https://maps.app.goo.gl/7oTvhzV8hiR5QAkf6"
                  target="_blank"
                  className="address"
                >
                
                  Purushottam Nagar, Taluka Shahada, District Nandurbar-425409
                </a>
                <p>
                  This unit features a sugar crushing capacity of 5000 TCD,
                  supported by a distillery producing 80 KLPD. It also includes
                  a petrol pump, a bottling project, and an organic fertiliser
                  project, ensuring a well-rounded and sustainable operational
                  framework.
                </p>
              </div>
            </div> */} 























          {/* </div>
        </div>
      </div>  */}
      {/* <Factory
        factory_num="Factory 1"
        factory_name="Onkar Sakhar Karkhana Pvt. Ltd."
        factory_address="Chandapuri, Taluka Malshiras, District Solapur-413310"
        factory_location="https://maps.app.goo.gl/39vaQrupC8M6eaE17"
        factory_content="This unit boasts a sugar daily crushing capacity of 6500 TCD,
                  accompanied by a distillery producing 150 KLPD and a
                  cogeneration facility generating 20 MW of power. Situated in
                  Chandapuri, Tal. Malshiras, Dist. Solapur, the unit also hosts
                  a bio CNG project with a capacity of 12 TPH, a compost
                  fertiliser project producing 200 MT, and an Indian Oil petrol
                  pump."
        factory_img={fac1}
      />
      <Factory
        factory_num="Factory 2"
        factory_name="Onkar Sakhar Karkhana Pvt. Ltd."
        factory_address="Ambulga, Taluka Nilanga, District Latur-413530"
        factory_location="https://maps.app.goo.gl/dvKeZ6QcAEetcYd67"
        factory_content="This unit features a sugar crushing capacity of 4500 TCD,
                  supported by a distillery producing 150 KLPD. Additionally, it
                  includes a diesel pump, a CNG generation project, and an
                  organic fertiliser project, ensuring a comprehensive and
                  sustainable operational setup."
        factory_img={fac2}
      />
      <Factory
        factory_num="Factory 3"
        factory_name="Onkar Sugar and Distillery Power Pvt. Ltd."
        factory_address="Farale, Taluka Radhanagari, District Kolhapur-416231"
        factory_location="https://maps.app.goo.gl/aQ6uvyqoAB96rG2x9"
        factory_content="This unit is equipped with a sugar crushing capacity of 2500
                  TCD, complemented by a cogeneration facility generating 20 MW
                  of electricity, ensuring efficient energy utilisation and
                  sustainability. The integrated setup supports streamlined
                  operations and long-term productivity."
        factory_img={fac3}
      />
      <Factory
        factory_num="Factory 4"
        factory_name="Gauri Sugar and Distilleries Pvt. Ltd."
        factory_address="Hiradgaon, Taluka Shrigonda, District Ahmednagar-413701"
        factory_location="https://maps.app.goo.gl/xNkoLtmiUqs8HFun7"
        factory_content=" This unit boasts a sugar crushing capacity of 10,000 TCD,
                  accompanied by a distillery producing 80 KLPD and a
                  cogeneration facility generating 40 MW of electricity.
                  Additionally, the setup includes a diesel pump, ensuring
                  seamless operations and energy efficiency."
        factory_img={fac4}
      />
      <Factory
        factory_num="Factory 5"
        factory_name="Onkar Power Corporations Pvt. Ltd."
        factory_address="Tadwal, Taluka Akkalkot, District Solapur-413219"
        factory_location="https://maps.app.goo.gl/1NziCSkRFd2bVwtq6"
        factory_content="This unit features a sugar crushing capacity of 6500 TCD,
                  supported by a cogeneration facility generating 20 MW of
                  electricity. The integrated design ensures efficient energy
                  utilisation and sustainable operations."
        factory_img={fac5}
      />
      <Factory
        factory_num="Factory 6"
        factory_name="Onkar Sugar Corporations Pvt. Ltd."
        factory_address="Mhaisgaon, Taluka Madha, District Solapur-413208"
        factory_location="https://maps.app.goo.gl/hF7eMFJJPyv8uj2V6"
        factory_content="This unit is equipped with a sugar crushing capacity of 6000
                  TCD, complemented by a distillery producing 250 KLPD and a
                  cogeneration facility generating 20 MW of electricity. The
                  setup ensures high efficiency and sustainable operations."
        factory_img={fac6}
      />
      <Factory
        factory_num="Factory 7"
        factory_name="Onkar Sugar and Allied Industries Pvt.Ltd."
        factory_address="Deodaithan, Taluka Shrigonda, District Ahmednagar-413702"
        factory_location="https://maps.app.goo.gl/fxcixis5LQFJePjB9"
        factory_content="This unit includes a sugar crushing capacity of 2000 TCD,
                  supported by a cogeneration facility generating 5 MW of
                  electricity. Additionally, it features a diesel pump to
                  facilitate seamless and efficient operations."
        factory_img={fac7}
      />
      <Factory
        factory_num="Factory 8"
        factory_name="Onkar Sakhar Karkhana Pvt. Ltd."
        factory_address="Pangari, Taluka Parli, District Beed-431530"
        factory_location="https://maps.app.goo.gl/8oPsix8C3upATLhV8"
        factory_content=" This unit boasts a sugar crushing capacity of 6500 TCD,
                  accompanied by a distillery producing 75 KLPD and a
                  cogeneration facility generating 20 MW of electricity. The
                  setup ensures efficient and sustainable operations."
        factory_img={fac8}
      />
      <Factory
        factory_num="Factory 9"
        factory_name="Onkar Sugar and Allied Industries Pvt. Ltd."
        factory_address="Purushottam Nagar, Taluka Shahada, District Nandurbar-425409"
        factory_location="https://maps.app.goo.gl/7oTvhzV8hiR5QAkf6"
        factory_content=" This unit features a sugar crushing capacity of 5000 TCD,
                  supported by a distillery producing 80 KLPD. It also includes
                  a petrol pump, a bottling project, and an organic fertiliser
                  project, ensuring a well-rounded and sustainable operational
                  framework."
        factory_img={fac9}
      /> */}
    </>
  );
}

export default Factories;
